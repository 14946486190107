import React from "react";
import Layout from '../components/layout';
import { graphql } from "gatsby";
import ImageLoader from "../components/image-loader";
import pagesStyles from "../styles/pages.module.css"
import Card from "../components/card";
import TeamLoader from "../components/team-loader";

export default function Team({ data }) {
  return (
    <div>
      <Layout>
        <ImageLoader data={data}>
          <h1 className={pagesStyles.mbNormal}>{data.allMarkdownRemark.edges[0].node.frontmatter.title}</h1> 
          <div className={pagesStyles.mbNormal}>{formatText(data)}</div>
          <TeamLoader/>
        </ImageLoader>
      </Layout>
    </div>
  )
}

function formatText(data) {
  let lines = data.allMarkdownRemark.edges[0].node.rawMarkdownBody.split('\n');
  let formatted = []
  let count = 0;
  for (let line of lines) {
    count++;
    formatted.push(<p key={count}>{line}</p>)
  }
  return formatted;
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {page: {eq: "team"}}}) {
      edges {
        node {
          frontmatter {
            title
            page
            images {
              image
            }
            imagePosition
            rowCount
          }
          rawMarkdownBody
        }
      }
    }
  } 
`

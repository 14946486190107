import React from 'react';
import teamStyles from '../styles/team.module.css'
import '../styles/fontawesome-free-5.14.0-web/css/all.css'

export default class Card extends React.Component {
    
    render() {
        let data = this.props.data.node.frontmatter;
        
        return(
            <div className={teamStyles.card + " animate__animated animate__fadeInUp animate__slow" }>
                <img src={data.picture} className={teamStyles.image} alt="team member"/>
                <div className={teamStyles.cardContent}>
                    <div className={teamStyles.title}>{data.firstName}</div>
        {data.description ? <div className={teamStyles.contentText}>{data.description}</div> : '' }
                    <div className={teamStyles.content}>
                        <span className={`fa fa-envelope ${teamStyles.icon}`}></span>
                        <span className={teamStyles.mail}>{data.email}</span>
                    </div>
                </div>
            </div>
                    
        )
    }      
    
}

import React from 'react';
import footerStyles from '../styles/footer.module.css'
import '../styles/fontawesome-free-5.14.0-web/css/all.css'
import InfoBox from './info-box.js'
import { Link, graphql, StaticQuery } from 'gatsby';
import Card from './card';
// import footerElements from './footer-elements'

export default class TeamLoader extends React.Component {

    getTeam(data) {
        let teamData = data.allMarkdownRemark.edges;
        let team = [];
        let i = 0;
        for (let member of teamData) {
            i++
            team.push(<Card key={i} data={member}/>)
        }
        return team;
    }
    
    render() {
        return(
        <StaticQuery query={
                            graphql`
                                query {
                                    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(teammembers)/"}}) {
                                      edges {
                                        node {
                                          frontmatter {
                                            firstName,
                                            lastName,
                                            description,
                                            email,
                                            picture
                                          }
                                        }
                                      }
                                    }
                                  }                                  
                                `
                            } render={data => (
                                <span>
                                    {this.getTeam(data)}
                                </span>
                        )} />  
        )
    }      
}

